import * as ActionType from './types/ModalActionTypes';
import { ModalActionTypes } from './types/ModalActionTypes';

export const hideModal = (): ModalActionTypes => ({ type: ActionType.HIDE_MODAL });

// Used to visually hide the modal but keep the componet fully rendered
// Useful if you have another modal that needs to appear (ex. web-nav)
export const suspendModal = (): ModalActionTypes => ({ type: ActionType.SUSPEND_MODAL });
export const reviveModal = (): ModalActionTypes => ({ type: ActionType.REVIVE_MODAL });

export const showModal = (
  modalType: string,
  modalProps = {},
  modalOptions = {}
): ModalActionTypes => ({
  type: ActionType.SHOW_MODAL,
  payload: {
    modalType,
    modalProps,
    modalOptions,
  },
});

export const hideSecondaryModal = (): ModalActionTypes => ({
  type: ActionType.HIDE_SECONDARY_MODAL,
});

export const showSecondaryModal = (
  modalType: string,
  modalProps = {},
  modalOptions = {}
): ModalActionTypes => ({
  type: ActionType.SHOW_SECONDARY_MODAL,
  payload: {
    modalType,
    modalProps,
    modalOptions,
  },
});
